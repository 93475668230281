<template>

 <div class="col-12"> 
 <h2>積雪IoT</h2>
      
       <div v-for="(group, tag) in groupedData" :key="tag">
     <h3> {{ tag }} </h3>

    
      <router-link :to="{ name: 'SnowDeviceAlert', params: {tag:tag , node_ids: group.map(obj => Object.values(obj)[0]) } }" >
    <span style="cursor: pointer;  font-weight: bold; ">アラート設定 </span>

      </router-link>

      <div class="table-responsive">
        <table class="table table-striped table-sm"  >
          <thead>
            <tr>
              <th>ID</th>
              <th>センサ名</th>
            </tr>
          </thead>
          
          <tbody >
                    
              <tr style="background:white !important;" v-for="(device, key) in group " :key="key">
                <td >
                
                
                <router-link :to="{path: `/snow/${device.node_id}`}">
                 <span style=" width: 100%; display: inline-block;">  {{ device.node_id }}</span >
                </router-link>
                </td>
                
                <td>
                  <router-link :to="{path: `/snow/${device.node_id}`}">
                  <span style=" width: 100%; display: inline-block;">{{ device.name}}</span >
                      </router-link>
                </td>
            </tr>
          </tbody>
        </table>
   
      </div>
    </div>
  
</div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "SnowDevice2023",
  data() {
    return { 
      groupedData: {},
      isReverse: false, // Track if sort order is reversed
      groupCriteria: 'tag' // Default grouping by tag
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const response = await fetch("/api/v4/sensors/?sensor_type=34", {
          method: 'GET',
          credentials: 'include'
        });
        const json = await response.json();
        
        if (json.data) {
          this.groupBy(json.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    groupBy(data) {
      // Group data by selected criteria (tag or name)
      const groupedData = data.reduce((acc, obj) => {
        const key = obj[this.groupCriteria];
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }, {});

      // Sort the grouped data by key, using the reverse option if set
      this.groupedData = Object.keys(groupedData)
        .sort((a, b) => this.isReverse ? b.localeCompare(a) : a.localeCompare(b))
        .reduce((acc, key) => {
          acc[key] = groupedData[key];
          return acc;
        }, {});
    }
  },
  watch: {
    // Watch for changes in isReverse or groupCriteria and re-group data
    isReverse() {
      this.groupBy(Object.values(this.groupedData).flat());
    },
    groupCriteria() {
      this.groupBy(Object.values(this.groupedData).flat());
    }
  }
});
</script>
